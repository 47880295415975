.Container {
  position: fixed;
  width: fit-content;
  height: 64px;
  z-index: 10;
  transition: var(--animationTime) background, var(--animationTime) font-size, var(--animationTime) opacity;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
  user-select: none;
  opacity: 0.5;
  right: 0;
  
  &:hover {
    opacity: 1;
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: fit-content;
    display: flex;
    gap: 16px;
    li {
      transition: var(--animationTime) background-size, var(--animationTime) color;
      display: inline-block;
      
      background-image: linear-gradient(to left, #eee, #eee);
      background-repeat: no-repeat;
      background-size: 100% 10%;
      background-position: 0 100%;
      color: #eee;
      
      &:first-child {
        font-weight: 800;
      }
      
      &:hover {
        background-size: 100% 100%;
        cursor: pointer;
        color: #222;
      }
    }
  }

  .Locales {
    width: 32px;
    height: 20px;
    margin-left: 16px;
    margin-right: -48px;
    transition: var(--animationTime) margin-right;
    position: relative;
    
    img {
      z-index: 111;
      display: inline-block;
      position: relative;
      cursor: pointer;
      box-shadow: 0 0 8px #2228;
    }
    
    .LocalesList {
      position: absolute;
      top: -8px;
      left: -8px;
      width: 48px;
      padding-top: 32px;
      background: var(--backgroundColor);
      z-index: 0;
      padding-left: 8px;
      overflow: hidden;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform var(--animationTime);
      
      img {
        margin-top: 4px;
        cursor: pointer;
      }
    }
    
    &:hover, .LocalesList:hover {
      .LocalesList {
        transform: scaleY(1);
        height: auto;
      }
    }
  }
}

.ContainerOnTop {
  opacity: 1;

  .Locales {
    margin-right: 0;
  }
}

@media (max-width: 655px) {
  .Container {
    height: fit-content;
    background: none !important;
    padding: 0;
    opacity: 0.5;
    transition: var(--animationTime) opacity;
    flex-direction: row-reverse;
    width: 100%;
    
    &:hover {
      opacity: 1;
    }
    
    ul {
      flex-direction: column;
      width: 100%;
      text-align: right;
      padding: 16px;
      
      li {
        font-size: 14px;
      }
    }
    
    .Locales {
      transition: var(--animationTime) margin;
      margin-left: -32px;
      margin-right: 0;
    }
  }
  
  .ContainerOnTop {
    opacity: 1;

    .Locales {
      margin-left: 12px;
      margin-right: 0;
    }
  }
}