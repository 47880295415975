@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&family=Roboto+Condensed:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');

:root {
  --animationTime: 0.15s;
  --scrollAnimationTime: 0.5s;
  
  --defaultColor: rgb(0, 200, 0);// #5352ed;

  --defaultColorAlpha: rgba(0, 200, 0, 0.265625);// #5352ed44;
  
  --defaultColorText: #fff8;
  
  --textColor: #eee;
  
  --backgroundColor: #141414;
}

* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  color: var(--textColor);
  
  ::-webkit-scrollbar {
    width: 0;
  }
}

body {
  background-color: #111;
  // background-image: url('../public/bg.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  
  & > div, & > div > div {
    width: 100%;
    height: 100%;
  }
}

.Wrapper {
  overflow: hidden;
}

.Content {
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, var(--defaultColorAlpha), var(--defaultColor));
  overflow: auto;
}

.ContentMobile {
  overflow-y: auto;
}

.FancyButton {
  border: 2px solid #eee;
  color: #eee;
  background-color: transparent;
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  outline: 0;
  padding: 4px 8px;
  cursor: pointer;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  
  background-image: linear-gradient(to top, #eee4, #eee4);
  background-repeat: no-repeat;
  background-size: 0 0;
  background-position: 0 100%;
  
  transition: var(--animationTime) background-size;
  
  &:hover {
    background-size: 100% 100%;
  }
  
  svg {
    font-size: 20px;
    margin-right: 6px;
  }
}

.SubpageContainer {
  width: 100%;
  //height: 100vh;
  
  &:nth-child(even) {
    
  }
}