.Container {
  padding: 16px;
}

.CareerBox {
  border-left: 3px solid white;
  margin-left: 16px;
  padding-top: 32px;
  position: relative;
}

.Arrow {
  position: absolute;
  bottom: -12px;
  left: -9.5px;
  width: 16px;
  aspect-ratio: 1;
  background: transparent;
  border: 8px solid transparent;
  border-top: 8px solid white;
}

.Beginning {
  font-size: 20px;
  font-weight: 800;
  padding-left: 16px;
  color: #aaa;
  margin-bottom: 64px;
}

.JobBox {
  position: relative;
  padding-left: 48px;
  margin-bottom: 16px;
  
  .JobLine {
    position: absolute;
    width: 32px;
    height: 3px;
    background: white;
    top: 16px;
    margin: auto 0;
    left: 0;
  }
  .JobTop {
    display: flex;
    gap: 8px;
    align-items: flex-end;
    margin-bottom: 4px;
    
    .JobName {
      font-size: 24px;
      font-weight: 800;
    }
    .JobCompany {
      font-size: 16px;
      color: #aaa;
      font-weight: 700;
    }
    .JobDate {
      color: #aaa;
      span {
        background: var(--defaultColorAlpha);
      }
    }
  }
  
  .Things {
    
    .Thing {
      color: #aaa;
    }
  }
}

@media (max-width: 800px) {
  .JobTop {
    flex-direction: column;
    align-items: flex-start !important;
    padding-bottom: 4px;
  }
}