.Container {
  width: fit-content;
  height: fit-content;
  bottom: 16px;
  right: 16px;
  z-index: 200;
  pointer-events: none;
  display: flex;
  gap: 8px;
  padding: 0 16px;
}

.Button {
  pointer-events: all;
  border-radius: calc(40px / 2);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--animationTime);
  background: #333;
  cursor: pointer;
  font-size: 20px;
  
  &:hover {
    background: #444;
  }
  
  * {
    color: #ccc;
    transition: var(--animationTime)
  }
}

@mixin Button($color1, $color2) {
  &:hover {
    background:$color1;
    box-shadow: 0 0 12px $color1;
    * {
      color: $color2;
    }
  }
}

.DiscordButton {
  @include Button(#5865f288, #5865f2);
}

.GithubButton {
  @include Button(#bd2c0088, #bd2c00);
}

.FacebookButton {
  @include Button(#1877f288, #1877f2);
}

.LinkedinButton {
  @include Button(#0a66c288, #0a66c2);
}