.Container {
  padding: 16px;
}

.MainTitle {
  font-size: 32px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  border-bottom: 3px solid #eee;
  width: fit-content;
  padding-bottom: 4px;
  padding-left: 32px;
  margin-left: -16px;
}

.ProjectsContainerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProjectsContainer {
  padding-top: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ProjectContainer {
  aspect-ratio: 2/1;
  vertical-align: top;
  display: inline-block;
  padding: 16px;
  position: relative;
  width: min(600px, 100%);
}

.Project {
  width: 100%;
  height: 100%;
  border: 2px solid #ddd;
  display: flex;
  transition: box-shadow, margin;
  transition-duration: var(--animationTime);
  user-select: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  .Circle {
    --shadowColor: rgba(190, 190, 210, 0.02);
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    border-radius: 50%;
    background: var(--shadowColor);
    padding: calc(1vh + 1vw);
    box-shadow: var(--shadowColor);
    transition: width, height, opacity;
    transition-duration: var(--animationTime);
    opacity: 0;
  }

  .CircleInner {
    background: var(--shadowColor);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: calc(1vh + 1vw);
    box-shadow: 0 0 20px var(--shadowColor);
    pointer-events: none;
  }
  
  &:hover {
    .Circle {
      width: calc(15vh + 15vw);
      height: calc(15vh + 15vw);
      opacity: 1;
    }
    box-shadow: 8px 8px 0 #ddd;
    margin-top: -8px;
    margin-left: -8px;
  }

  &:active {
    box-shadow: none;
    margin: 0;
  }
}

.ProjectHalf {
  padding: 8px;
  flex: 1;
  z-index: 1;
  &:first-child {
    padding: 16px 8px 16px 16px;
  }
  
  &:nth-child(2) {
    padding: 16px 16px 16px 16px;
  }
}

.ProjectImage {
  height: 100%;
  aspect-ratio: 1;
  background: pink;
  
  img {
    width: 100%;
    height: 100%;
  }
}

.TagsContainer {
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  gap: 4px;
  width: 50%;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.Tag {
  background: var(--defaultColorAlpha);
  color: var(--defaultColor);
  border-radius: 10000px;
  padding: 2px 8px;
}

.ProjectRightSide {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 12px;
}

.ProjectTitle {
  font-size: 24px;
  font-weight: 700;
  color: #ccc;
  text-align: right;
  overflow: hidden;
}

.ProjectDescription {
  font-size: 16px;
  color: #aaa;
  text-align: right;
  width: 200px;

  overflow: hidden;
}

@media (max-width: 600px) {
  .ProjectContainer {
    aspect-ratio: auto !important;
    
    .Project {
      flex-direction: column;
      align-items: flex-end;
      
      .ProjectHalf {
        width: 100%;
        
        &:nth-child(2) {
          padding: 16px;
          width: 100%;
          .ProjectImage {
            width: 100%;
          }
        }

        &:nth-child(3) {
          padding-top: 0;
          margin-top: -8px;
        }
        
        .ProjectRightSide {
          padding-right: 8px;
        }
      }

      &:hover {
        box-shadow: 0 0 8px var(--textColor);
        margin: 0;
      }

      &:active {
        box-shadow: none;
      }
    }
  }

  .TagsContainer {
    position: relative;
    width: calc(100% - 32px);
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 16px;
  }
}

//@media (max-width: 2500px) {
//  .ProjectContainer {
//    width: 20%;
//  }
//}
//
//@media (max-width: 4000px) {
//  .ProjectContainer {
//    width: 20%;
//  }
//}
//
//@media (max-width: 3000px) {
//  .ProjectContainer {
//    width: 25%;
//  }
//}
//
//@media (max-width: 2000px) {
//  .ProjectContainer {
//    width: 33.33%;
//  }
//}
//
//@media (max-width: 1500px) {
//  .ProjectContainer {
//    width: 50%;
//  }
//}
//
//@media (max-width: 1000px) {
//  .ProjectContainer {
//    width: 100%;
//  }
//}