.Container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 0;
}

.ParticlesContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  .Particle {
    --color: #aaa2;
    aspect-ratio: 1;
    background: var(--color);
    box-shadow: 0 0 12px var(--color), 0 0 12px var(--color);
    position: absolute;
    border-radius: 50%;
    animation-name: Particle;
    animation-iteration-count: infinite;
    opacity: 0.6;
  }
}

@keyframes Particle {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: -32px;
  }
  100% {
    margin-top: 0;
  }
}

.CenterContainer {
  width: 100%;
  height: fit-content;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

@mixin Line {
  font-family: "Black Ops One", system-ui;
  font-weight: 400;
  font-size: min(72px, 12vw);
  line-height: min(72px, 12vw);
  margin: 0;
  color: transparent;
}

.Line {
  @include Line;
  font-size: min(108px, 18vw);
  line-height: min(100px, 18vw);
  opacity: 0.75;
  color: var(--defaultColorText);
}

.LineAnimation {
  @include Line;
  color: var(--defaultColorText);
  font-family: "Black Ops One", system-ui;
  font-weight: 400;
  text-align: center;
  max-width: 100vw;
  overflow: hidden;
}