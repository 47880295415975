.Container {
  height: fit-content;
  
  display: flex;
  gap: 16px;
  padding: 16px;
}

.HalfBox {
  flex: 1;
  display: flex;
  justify-content: center;

  flex-direction: column;
  &:nth-child(1) {
    .TextBox {
      display: flex;
      flex-direction: column;
    }
  }
  
  &:nth-child(2) {
    padding: 32px 32px 32px 32px;
    align-items: flex-start;
  }
}

.AvatarBox {
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  justify-content: flex-end;;
  
  img {
    width: min(calc(100vh - 86px), 50vw); 
    height: min(calc(100vh - 86px), 50vw);
  }
}

.TextBox {
  width: 100%;
  height: 100%;
  
  p {
    margin: 0;
    padding: 16px;
    font-size: max(16px, 1.2dvw);
    color: #999;
    text-align: justify;
    
    span {
      background: var(--defaultColorAlpha);
    }
  }
}

.Email {
  a {
    text-decoration: none;
    color: var(--defaultColor) !important;
    transition: var(--animationTime);
    
    &:hover {
      color: var(--defaultColorAlpha) !important;
    }
  }
}

.DownloadCVButton {
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: calc(40px * 4 + 8px * 3);
  border-radius: 20px;
  border: 0;
  background: var(--defaultColorAlpha);
  color: var(--defaultColor);
  font-size: 16px;
  font-weight: 700;
  padding: 0 16px;
  transition: var(--animationTime);
  cursor: pointer;
  
  &:hover {
    background: var(--defaultColor);
    color: var(--defaultColorText);
    box-shadow: 0 0 12px var(--defaultColor);
  }
}

@media (max-width: 800px) {
  .Container {
    flex-direction: column-reverse;
  }
  
  .TextBox {
    p {
      font-size: 18px;
    }
  }
  
  .AvatarBox {
    align-items: flex-end;
    justify-content: center;
    aspect-ratio: inherit;
    
    img {
      width: min(50vh, 90vw);
      height: min(50vh, 90vw);
    }
  }
}